import { makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getTermsOfUse = async (): Promise<{ mainText: string }> => {
    const result = await makeGetRequest(API.terms.get);

    return result.data.result.data.items;
};

export const editTermsOfUse = async (data: any): Promise<{ mainText: string }> => {
    const result = await makeFormRequest(API.terms.edit, data);

    return result.data.result.item.items;
};
